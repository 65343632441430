<template>
  <div id="intellect_device">
    <div class="banner">
      <p>智能物流装备</p>
      <p>
        九州云智智能装备系列产品，构建了模式自由组合、应用灵活扩展、场景广泛适配的一体化智能物流解决方案。
      </p>
    </div>
    <div class="container">
      <div class="floor_1 floor">
        <div class="floor1_title">
          <div class="floor1_t1">穿梭车系列产品</div>
          <div class="floor1_t2">高存储密度、高存取效率、高扩展性</div>
        </div>
        <div class="floor1_main">
          <div class="item">
            <img src="~assets/image/smartDevice/f1_1.png" alt="" />
            <div>高速式穿梭车</div>
          </div>
          <div class="item">
            <img src="~assets/image/smartDevice/f1_2.png" alt="" />
            <div>全向式穿梭车</div>
          </div>
          <div class="item">
            <img src="~assets/image/smartDevice/f1_3.png" alt="" />
            <div>变量式穿梭车</div>
          </div>
          <div class="item">
            <img src="~assets/image/smartDevice/f1_4.png" alt="" />
            <div>重载式穿梭车</div>
          </div>
        </div>
      </div>
      <div class="floor_2 floor">
        <div class="floor2_title">
          <div class="floor2_t1">搬运机器人系列产品</div>
          <div class="floor2_t2">高精确度、高适应性、高柔性化</div>
        </div>
        <div class="floor2_main">
          <div class="item">
            <img src="~assets/image/smartDevice/f2_1.png" alt="" />
            <div>潜伏机器人</div>
          </div>
          <div class="item">
            <img src="~assets/image/smartDevice/f2_2.png" alt="" />
            <div>移载机器人</div>
          </div>
          <div class="item">
            <img src="~assets/image/smartDevice/f2_3.png" alt="" />
            <div>料箱到人机器人</div>
          </div>
        </div>
      </div>
      <div class="floor_3 floor">
        <div class="floor3_title">
          <div class="floor3_t1">穿梭车零货自动拣选方案</div>
        </div>
        <div class="floor3_main">
          <div class="left">
            <h3>
              支持货物的多深位密集存储和处理，相比传统零货处理效率提升5~10倍。
              <br />
              箱式穿梭车、提升机和货到人拣选系统对接，全自动出入库，快速的拆零拣选作业。
              <br />
              穿梭车支持双向/四向行走，系统可根据业务需求柔性灵活扩展配置。
            </h3>
            <!-- <div class="des">
                <div>效率提升5~10倍</div>
                <div>全自动出入库</div>
                <div>柔性灵活扩展配置</div>
              </div> -->
            <div class="btn"><a href="">查询方案</a></div>
          </div>
          <div class="right">
            <img src="~assets/image/smartDevice/f3_1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="floor_4 floor">
        <div class="floor4_title">
          <div class="floor4_t1">穿梭车整件自动拣选方案</div>
        </div>
        <div class="floor4_main">
          <div class="left">
            <h3>
              高密度存储，较同等立体库相比，存储密度提升20%~30%。
              <br />
              托盘式四向穿梭车、提升机和桁架机械手拣选系统对接，全自动出入库，实现在线拣选功能。
              <br />
              可根据效率需求调整穿梭车数量，提升存储能力和吞吐流量，灵活适应各种复杂的仓库结构。
            </h3>
            <!-- <div class="des">
                <div>效率提升5~10倍</div>
                <div>全自动出入库</div>
                <div>柔性灵活扩展配置</div>
              </div> -->
            <div class="btn"><a href="">查询方案</a></div>
          </div>
          <div class="right">
            <img src="~assets/image/smartDevice/f4_1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="floor_5 floor">
        <div class="floor5_title">
          <div class="floor5_t1">搬运机器人自动拣选方案</div>
        </div>
        <div class="floor5_main">
          <div class="left">
            <h3>
              可节省30%~50%人工，达到人工拣选2~3倍的工作效率。
              <br />
              AI算法，实现机器人调度控制的最优路径，最少交叉与最短等待。
              <br />
              多类型搬运机器人与拣选工作站自由组合，可柔性增减机器人数量，满足不同业务量的需求。
            </h3>
            <!-- <div class="des">
                <div>效率提升5~10倍</div>
                <div>全自动出入库</div>
                <div>柔性灵活扩展配置</div>
              </div> -->
            <div class="btn"><a href="">查询方案</a></div>
          </div>
          <div class="right">
            <img src="~assets/image/smartDevice/f5_1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntellectDevice",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
#intellect_device {
  width: 100%;
  font-family: Microsoft YaHei;
  .banner {
    position: relative;
    width: 100%;
    height: 3.62rem;
    background: #ffffff;
    background: url("../assets/image/smartDevice/bg_2.png") no-repeat;
    background-size: cover;
    padding: 0.91rem 0 0 3.2rem;
    p:nth-child(1) {
      font-size: 0.36rem;
      font-weight: 600;
      color: #525252;
    }
    p:nth-child(2) {
      margin-top: 0.34rem;
      width: 4.39rem;
      height: 0.41rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: #838383;
      line-height: 0.24rem;
    }
  }
  .container {
    [class^="floor_"] {
      padding-top: 1.22rem;
      [class$="_title"] {
        text-align: center;
        font-size: 0.4rem;
        font-weight: bold;
        color: #383838;
        [class$="_t1"] {
          font-size: 0.4rem;
          font-weight: bold;
          color: #232323;
        }
        [class$="_t2"] {
          height: 0.2rem;
          margin-top: 0.32rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #323333;
        }
      }
      [class$="_main"] {
        display: flex;
        justify-content: center;
        margin-top: 0.62rem;
        // margin: 0.62rem auto 0;
        // width: 12.8rem;
        height: 4.61rem;
      }
    }
    .floor_1 {
      .floor1_main {
        .item {
          width: 3.05rem;
          height: 3.51rem;
          text-align: center;
          box-shadow: 0 0 0.09rem #cccc;
          img {
            margin-top: 0.49rem;
            width: 3rem;
            height: 2.34rem;
          }
          div {
            margin-top: 0.23rem;
            font-size: 0.18rem;
            font-weight: 400;
            color: #505050;
          }
        }
        .item:nth-of-type(n + 2) {
          margin-left: 0.2rem;
        }
      }
    }
    .floor_2 {
      background: #f7f7f7;
      .floor2_main {
        .item {
          width: 4.06rem;
          height: 3.51rem;
          text-align: center;
          box-shadow: 0 0 0.09rem #cccc;
          background: #ffffff;
          img {
            margin-top: 0.04rem;
            width: 3rem;
            height: 3rem;
          }
          div {
            font-family: Source Han Sans CN;
            margin-bottom: 0.33rem;
            font-size: 0.16rem;
            font-weight: 400;
            color: #666666;
          }
        }
        .item:nth-of-type(n + 2) {
          margin-left: 0.25rem;
        }
      }
    }

    .floor_3,
    .floor_4,
    .floor_5 {
      height: 7.58rem;
      .floor3_main,
      .floor4_main,
      .floor5_main {
        .left {
          margin-left: 1.6rem;
          h3 {
            // width: 5.51rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.3rem;
            text-align: left;
          }
          .des {
            display: flex;
            margin-top: 0.26rem;
            div {
              display: inline-block;
              margin-right: 0.08rem;
              height: 0.22rem;
              background: #f0f3f7;
              border: 0.01rem solid #b5b5b5;
              font-size: 0.14rem;
              font-weight: 400;
              color: #666666;
              line-height: 0.2rem;
            }
            div:nth-child(1) {
              width: 1.39rem;
            }
            div:nth-child(2) {
              width: 1.14rem;
            }
            div:nth-child(3) {
              width: 1.5rem;
            }
          }
          .btn {
            width: 1.53rem;
            height: 0.39rem;
            margin-top: 0.63rem;
            text-align: center;
            line-height: 0.39rem;
            background: #1562ec;
            border-radius: 0.02rem;
            a {
              font-size: 0.14rem;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
        .right {
          img {
            width: 9rem;
            height: 4.2rem;
          }
        }
      }
    }
    .floor_4 {
      background: #f7f7f7;
    }
  }
}
</style>